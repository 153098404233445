import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Aulas de Forró</S.Title>
            <S.Subtitle>Ritmo, Diversão e Qualidade de Vida</S.Subtitle>
            <S.Text>
              O forró é um dos gêneros musicais mais tradicionais do Brasil,
              trazendo em seu ritmo e em seus passos a identidade e a cultura
              nordestina. Muito mais do que uma simples expressão artística, o
              forró também proporciona diversos benefícios para a saúde física e
              mental. No Clube dos Funcionários, oferecemos aulas de forró para
              todas as idades, promovendo bem-estar, integração social e um
              estilo de vida mais ativo e saudável.
            </S.Text>
            <S.Text>
              <span>História do Forró</span>
              <br />O forró tem suas origens no Nordeste do Brasil, sendo
              fortemente popularizado por artistas como Luiz Gonzaga, que
              trouxeram o ritmo para todo o país. Com influências de gêneros
              europeus, como a polca e o baião, o forró se tornou uma das danças
              mais queridas do Brasil. Atualmente, ele possui diversas
              variações, como o forró tradicional, o universitário e o
              eletrônico, garantindo uma grande diversidade de estilos para quem
              deseja aprender e se divertir.
            </S.Text>
            <S.Text>
              <span>Benefícios da Dança</span>
              <br />A prática do forró vai muito além da diversão. A dança é uma
              excelente forma de exercício, que ajuda no desenvolvimento da
              coordenação motora, na memória e na agilidade. Além disso, é uma
              atividade que promove interação social, ajudando a combater a
              timidez e fortalecendo os laços de amizade. O forró também é uma
              excelente ferramenta para aliviar o estresse, pois libera
              endorfinas, proporcionando sensação de bem-estar e felicidade.
            </S.Text>
            <S.Text>
              <span>Benefícios da Atividade Física da Dança</span>
              <br />A dança é uma atividade física completa, que trabalha o
              corpo de forma equilibrada. Durante as aulas de forró, é possível
              melhorar a capacidade cardiorrespiratória, fortalecer os músculos
              e aumentar a flexibilidade. Além disso, a prática regular auxilia
              na perda de calorias, contribuindo para a manutenção do peso
              corporal e prevenindo doenças como hipertensão e diabetes. Outro
              aspecto positivo é a melhora na postura e no equilíbrio, fatores
              essenciais para a qualidade de vida e prevenção de lesões. No
              Clube dos Funcionários, nossas aulas de forró são conduzidas por
              professores qualificados e em um ambiente descontraído. Venha
              aprender a dançar, se exercitar e se divertir! Entre em contato
              conosco e inscreva-se nessa experiência cheia de ritmo e alegria.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
          </S.ContentBlock>
          <S.ColumnsView>
            <S.ContentBlock>
              <S.Subtitle>Horário das Aulas</S.Subtitle>
              <S.Text>
                Quinta-feira aula de forró de 19h as 20h30
                <br />
                R$ 70,00 - sócio
                <br />
                R$ 90,00 - não sócio
                <br />
              </S.Text>
              <S.Text>
                Matrícula: Matrícula não sócio: R$ 50,00
                <br />
                <br />
                ALUNOS NÃO ASSOCIADOS QUE FAZEM A AULA DE FORRÓ JUNTAMENTE COM A
                AULA DE DANÇA DE SALÃO PAGARÃO DE MENSALIDADE NO FORRÓ R$60,00.
              </S.Text>
            </S.ContentBlock>
          </S.ColumnsView>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};

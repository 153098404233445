import { Location, RouteObject } from "react-router-dom";

import { PowersPage, TransparencyPage } from "pages/Administration";
import { ArticlePage, ArticlesPage } from "pages/Articles";
import { CBCCovenantPage } from "pages/CBCCovenant";
import {
  Cine9DeAbrilPage,
  ClubAnthemPage,
  ClubHistoryPage,
  FAQPage,
  LocationPage,
  MagazinePage,
  PetPage,
  SocialOfficePage,
  MissionPage,
} from "pages/Club";
import { EventPage, EventsPage } from "pages/ClubEvents";
import { ContactPage, OmbudsmanPage } from "pages/Contact";
import { GalleriesPage, GalleryPage } from "pages/Galleries";
import { GeneralSearch } from "pages/GeneralSearch";
import { HomePage } from "pages/Home";
import { TreesPage } from "pages/Trees/treesPage";
import { TreeDetails } from "pages/Trees/details";

import { MinutePage } from "pages/Minutes";
import {
  BalletPage,
  BallroomPage,
  BasketBallPage,
  BeachTennisPage,
  ChessPage,
  DivingPage,
  FunctionalTrainingPage,
  FutsalPage,
  FutvoleyPage,
  GymnasticArtisticPage,
  GymPage,
  HandballPage,
  JiujitsuPage,
  JoggingPage,
  JudoPage,
  KaratePage,
  PadelPage,
  SnookerPage,
  SoccerFemPage,
  SoccerPage,
  StepPage,
  SwimmingPage,
  TaekwondoPage,
  TennisPage,
  VolleyPage,
  WaterAerobicsPage,
  MuayPage,
  ForroPage,
} from "pages/Sports";

interface Props {
  isLoggedIn: boolean;
  location: Location;
}

const routes = ({ isLoggedIn, location }: Props): RouteObject[] => [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "sitemap",
    element: <GeneralSearch />,
  },
  {
    path: "arvores-do-clube",
    element: <TreesPage />,
  },
  {
    path: "arvores-do-clube/:slug",
    element: <TreeDetails />,
  },
  {
    path: "administracao",
    children: [
      {
        path: "poderes-do-clube",
        element: <PowersPage />,
      },
      {
        path: "transparencia",
        element: <TransparencyPage />,
      },
      {
        path: "documentos-e-atas",
        element: <MinutePage />,
      },
    ],
  },
  {
    path: "noticias",
    children: [
      {
        path: "",
        element: <ArticlesPage />,
      },
      {
        path: ":id/:slug",
        element: <ArticlePage />,
      },
    ],
  },
  {
    path: "eventos",
    children: [
      {
        path: "",
        element: <EventsPage />,
      },
      {
        path: ":id/:slug",
        element: <EventPage />,
      },
    ],
  },
  {
    path: "convenio-cbc",
    element: <CBCCovenantPage />,
  },
  {
    path: "clube",
    children: [
      {
        path: "historia",
        element: <ClubHistoryPage />,
      },
      {
        path: "missao-visao-valores",
        element: <MissionPage />,
      },
      {
        path: "hino",
        element: <ClubAnthemPage />,
      },
      {
        path: "sede-social",
        element: <SocialOfficePage />,
      },
      {
        path: "cine-9-de-abril",
        element: <Cine9DeAbrilPage />,
      },
      {
        path: "pet",
        element: <PetPage />,
      },
      {
        path: "faq",
        element: <FAQPage />,
      },
      {
        path: "informa-clube",
        element: <MagazinePage />,
      },
      {
        path: "localizacao",
        element: <LocationPage />,
      },
      // {
      //   path: "fale-conosco",
      //   element: <ContactTalkWithUsPage />,
      // },
      // {
      //   path: "ouvidoria",
      //   element: <ContactOmbudsmanPage />,
      // },
    ],
  },
  {
    path: "esportes",
    children: [
      {
        path: "academia",
        element: <GymPage />,
      },
      {
        path: "bale",
        element: <BalletPage />,
      },
      {
        path: "basquete",
        element: <BasketBallPage />,
      },
      {
        path: "beach-tennis",
        element: <BeachTennisPage />,
      },
      {
        path: "consultoria-de-corrida",
        element: <JoggingPage />,
      },
      {
        path: "curso-de-mergulho",
        element: <DivingPage />,
      },
      {
        path: "danca-de-salao",
        element: <BallroomPage />,
      },
      {
        path: "forro",
        element: <ForroPage />,
      },
      {
        path: "futebol",
        element: <SoccerPage />,
      },
      {
        path: "futebol-feminino",
        element: <SoccerFemPage />,
      },
      {
        path: "futsal",
        element: <FutsalPage />,
      },
      {
        path: "ginastica-artistica",
        element: <GymnasticArtisticPage />,
      },
      {
        path: "handball",
        element: <HandballPage />,
      },
      {
        path: "hidroginastica",
        element: <WaterAerobicsPage />,
      },
      {
        path: "jiu-jitsu",
        element: <JiujitsuPage />,
      },
      {
        path: "judo",
        element: <JudoPage />,
      },
      {
        path: "muay-thai",
        element: <MuayPage />,
      },
      {
        path: "karate",
        element: <KaratePage />,
      },
      {
        path: "natacao",
        element: <SwimmingPage />,
      },
      {
        path: "padel",
        element: <PadelPage />,
      },
      {
        path: "Step",
        element: <StepPage />,
      },
      {
        path: "sinuca",
        element: <SnookerPage />,
      },
      {
        path: "taekwondo",
        element: <TaekwondoPage />,
      },
      {
        path: "tenis",
        element: <TennisPage />,
      },
      {
        path: "treinamento-funcional",
        element: <FunctionalTrainingPage />,
      },
      {
        path: "volley",
        element: <VolleyPage />,
      },
      {
        path: "futvoley",
        element: <FutvoleyPage />,
      },
      {
        path: "xadrez",
        element: <ChessPage />,
      },
    ],
  },
  {
    path: "galerias",
    children: [
      {
        path: "",
        element: <GalleriesPage />,
      },
      {
        path: ":id/:slug",
        element: <GalleryPage />,
      },
    ],
  },
  {
    path: "contato",
    children: [
      {
        path: "fale-conosco",
        element: <ContactPage />,
      },
      {
        path: "ouvidoria",
        element: <OmbudsmanPage />,
      },
    ],
  },
];

export default routes;

import { NavigationMenuEntry } from "contracts/Common";

const administration: NavigationMenuEntry = {
  label: "Administração",
  children: [
    {
      label: "Os poderes do clube",
      path: "/administracao/poderes-do-clube",
    },
    {
      label: "Documentos e Atas",
      path: "/administracao/documentos-e-atas",
    },
    {
      label: "Transparência",
      path: "/administracao/transparencia",
    },
  ],
};

const cbcCovenant: NavigationMenuEntry = {
  label: "Convênio CBC",
  path: "/convenio-cbc",
};

const events: NavigationMenuEntry = {
  label: "Eventos",
  path: "/eventos",
};

const articles: NavigationMenuEntry = {
  label: "Notícias",
  path: "/noticias",
};

const club: NavigationMenuEntry = {
  label: "O Clube",
  children: [
    {
      label: "História do clube",
      path: "/clube/historia",
    },
    {
      label: "Hino",
      path: "/clube/hino",
    },
    {
      label: "Revista - Informa Clube",
      path: "/clube/informa-clube",
    },
    {
      label: "Visão, Missão e Valores",
      path: "/clube/missao-visao-valores",
    },
    {
      label: "Sede Social",
      path: "/clube/sede-social",
    },
    {
      label: "9 de Abril",
      path: "/clube/cine-9-de-abril",
    },
    {
      label: "PET - Praça de Esportes Tabajaras",
      path: "/clube/pet",
    },
    {
      label: "Informações e Dúvidas Frequentes",
      path: "/clube/faq",
    },
  ],
};

const sports: NavigationMenuEntry = {
  label: "Esportes",
  children: [
    {
      label: "Academia",
      path: "/esportes/academia",
    },
    {
      label: "Balé",
      path: "/esportes/bale",
    },
    {
      label: "Basquete",
      path: "/esportes/basquete",
    },
    {
      label: "Beach Tennis",
      path: "/esportes/beach-tennis",
    },
    // {
    //   label: "Consultoria de Corrida",
    //   path: "/esportes/consultoria-de-corrida",
    // },
    // {
    //   label: "Curso de mergulho",
    //   path: "/esportes/curso-de-mergulho",
    // },
    {
      label: "Dança de Salão",
      path: "/esportes/danca-de-salao",
    },
    {
      label: "Futebol",
      path: "/esportes/futebol",
    },
    {
      label: "Forró",
      path: "/esportes/forro",
    },
    // {
    //   label: "Futebol feminino",
    //   path: "/esportes/futebol-feminino",
    // },
    {
      label: "Futsal",
      path: "/esportes/futsal",
    },
    {
      label: "Futevôlei",
      path: "/esportes/futvoley",
    },
    {
      label: "Ginástica Artística",
      path: "/esportes/ginastica-artistica",
    },
    // {
    //   label: "Handebol",
    //   path: "/esportes/handball",
    // },
    {
      label: "Hidroginástica",
      path: "/esportes/hidroginastica",
    },
    // {
    //   label: "Jiu-Jitsu",
    //   path: "/esportes/jiu-jitsu",
    // },
    {
      label: "Judô",
      path: "/esportes/judo",
    },
    {
      label: "Muay Thai",
      path: "/esportes/muay-thai",
    },
    // {
    //   label: "Karatê",
    //   path: "/esportes/karate",
    // },
    {
      label: "Natação",
      path: "/esportes/natacao",
    },
    {
      label: "Padel",
      path: "/esportes/padel",
    },
    {
      label: "Step",
      path: "/esportes/step",
    },
    {
      label: "Sinuca",
      path: "/esportes/sinuca",
    },
    {
      label: "Taekwondo",
      path: "/esportes/taekwondo",
    },
    {
      label: "Tênis",
      path: "/esportes/tenis",
    },
    // {
    //   label: "Treinamento Funcional",
    //   path: "/esportes/treinamento-funcional",
    // },
    {
      label: "Vôlei",
      path: "/esportes/volley",
    },
    {
      label: "Xadrez",
      path: "/esportes/xadrez",
    },
  ],
};

const photos: NavigationMenuEntry = {
  label: "Fotos",
  path: "/galerias",
};

const location: NavigationMenuEntry = {
  label: "Localização",
  path: "/clube/localizacao",
};
const tour360: NavigationMenuEntry = {
  label: "Tour 360º",
  externalPath: "https://www.clubedosfuncionarios.com.br/tour/",
};

const portalSocio: NavigationMenuEntry = {
  label: "Portal do Sócio",
  externalPath: "https://portal.multiclubes.com.br/cfcsn/login.aspx",
};

const contact: NavigationMenuEntry = {
  label: "Contato",
  children: [
    {
      label: "Fale conosco",
      path: "/contato/fale-conosco",
    },
    {
      label: "Ouvidoria",
      path: "/contato/ouvidoria",
    },
  ],
};

export const navigationMenuEntries: NavigationMenuEntry[] = [
  administration,
  cbcCovenant,
  events,
  articles,
  club,
  sports,
  photos,
  location,
  tour360,
  portalSocio,
  contact,
];

export const footerMenu: NavigationMenuEntry[] = [
  administration,
  cbcCovenant,
  events,
  articles,
  photos,
  club,
  location,
  contact,
  sports,
];

import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Volei</S.Title>
            <S.Text>
              O voleibol foi criado no ano de 1895 por William G. Morgan, então
              Diretor de Educação Física da Associação Cristã de Moços, de
              Holyoke. Massachusetts-USA. O nome original da nova modalidade
              esportiva, por motivos não conhecidos, era MINONETTE. Morgan tinha
              sob sua direção classes numerosa de homens de idade já um tanto
              avançada, dentro eles homens de negócios. O basquetebol, outro
              esporte que recentemente aparecera e que rapidamente se difundira,
              era muito enérgico e extenuante. Morgan achou, então, que seria
              melhor para seus alunos, notadamente os mais velhos, a prática de
              um jogo menos fatigante que o da bola ao cesto. Levantou, pois,
              uma rede semelhante à de tênis, a uma altura de um metro e oitenta
              e três centímetros sobre a qual uma câmara de bola de basquetebol
              era batida, surgindo assim o esporte que futuramente seria
              denominado de voleibol. A bola primitiva não satisfez, em virtude
              de ser muito leve, e a de basquetebol completa, que também foi
              usada, ao contrário, era muito pesada, machucando as mãos dos
              praticantes.
            </S.Text>

            <S.RedSubtitle>
              SOMENTE COM A AULA EXPERIMENTAL ASSINADA PELO PROFESSOR
            </S.RedSubtitle>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>
              EQUIPE PRÉ MIRIM FEMININA (10 á 12 anos) Patrícia/Fernando
            </S.Subtitle>
            <S.Text>
              SEGUNDA E QUARTA - 18:00 ás 19:30
              <br />
              Sócio: R$R$ 97,00
              <br />
              Não Sócio: R$R$183,00
              <br />
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>
              EQUIPE MIRIM B FEMININA (13 E 14 anos) - Patrícia/Pedro{" "}
            </S.Subtitle>
            <S.Text>
              Segunda e Quarta 15:00 ás 16:30 e Sexta 14:00 ás 15:20
              <br />
              VALOR EQUIPE
              <br />
              SÓCIO: R$ 97,00
              <br />
              NÃO SÓCIO: R$183,00
              <br />
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>
              EQUIPE MIRIM A FEMININA(13 E 14 anos )Patrícia/Fernando
            </S.Subtitle>
            <S.Text>
              Segunda e Quarta 16:30 ás 18:00 e Sexta 15:20 ás 16:30
              <br />
              VALOR EQUIPE
              <br />
              SÓCIO: R$ 97,00
              <br />
              NÃO SÓCIO: R$183,00
              <br />
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>EQUIPE MIRIM (C) - (12 á 14 anos) - Pedro</S.Subtitle>
            <S.Text>
              SEGUNDA E QUARTA 16:30 ás 18:00
              <br />
              VALOR EQUIPE
              <br />
              SÓCIO: R$ 97,00
              <br />
              NÃO SÓCIO: R$183,00
              <br />
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>
              EQUIPE INFANTIL FEMININO (14 A 16 anos) Fernando
            </S.Subtitle>
            <S.Text>
              Segunda e Quarta 15:00 ás 16:30 e Sexta 14:00 ás 15:20
              <br />
              VALOR EQUIPE
              <br />
              SÓCIO: R$ 97,00
              <br />
              NÃO SÓCIO: R$183,00
              <br />
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>
              EQUIPE INFANTO FEMININO (17 e 18 anos) Fernando
            </S.Subtitle>
            <S.Text>
              Segunda e Quarta 19:30 ás 21:00 e Sexta 16:30 ás 18:00 <br />
              VALOR EQUIPE
              <br />
              SÓCIO: R$ 97,00
              <br />
              NÃO SÓCIO: R$183,00
              <br />
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>
              EQUIPE INFANTIL MASCULINO (14 á 16 anos) REGINALDO
            </S.Subtitle>
            <S.Text>
              TERÇA E QUINTA 16:00 ás 18:00 E SEXTA 17:00 AS 18:30
              <br />
              VALOR EQUIPE
              <br />
              SÓCIO: R$ 97,00
              <br />
              NÃO SÓCIO: R$183,00
              <br />
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>
              EQUIPE INFANTO MASCULINO (17 E 18 anos) REGINALDO
            </S.Subtitle>
            <S.Text>
              TERÇA, QUINTA 18:00 ás 20:00 - E SEXTA 18:30 AS 20:00
              <br />
              VALOR EQUIPE
              <br />
              SÓCIO: R$ 97,00
              <br />
              NÃO SÓCIO: R$183,00
              <br />
            </S.Text>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Subtitle>ESCOLINHA</S.Subtitle>
            <S.RedSubtitle>
              SOMENTE COM A AULA EXPERIMENTAL ASSINADA PELO PROFESSOR
            </S.RedSubtitle>
            <S.Text>
              SEGUNDA E QUARTA - Pedro (feminino)
              <br />
              19:30 ás 21:00 - PRÉ EQUIPE
              <br />
              FEMININO (14 á 17 anos) SÓCIO: R$ 76,00 NÃO SÓCIO: R$ 129,00
            </S.Text>
            <S.Text>
              SEGUNDA E QUARTA - Professor Pedro (feminino)
              <br />
              18:00 ás 19:00 - 10 á 12 anos (LIMITE DE 20)
              <br />
              SÓCIO: R$ 76,00
              <br />
              NÃO SÓCIO: R$ 129,00
            </S.Text>
            <S.Text>
              QUARTA E SEXTA - Professor Pedro (misto)
              <br />
              09:00 ás 10:30 - 08 á 12 anos (LIMITE 20 ALUNOS)
              <br />
              SÓCIO:R$ 76,00
              <br /> NÃO SÓCIO: R$ 129,00
            </S.Text>
            <S.Text>
              TERÇA E QUINTA - Professor Fernando (feminino)
              <br />
              17:00 ás 18:00 - 13 á 17 anos (LIMITE DE 20)
              <br />
              18:00 ás 19:00 - 08 á 10 anos (LIMITE DE 15)
              <br />
              SÓCIO: R$76,00
              <br />
              NÃO SÓCIO: R$129,00
            </S.Text>
            <S.Text>
              ESCOLINHA MASCULINO (12 á 17 anos) Reginaldo <br />
              TERÇA , QUINTA 14:00 ás 16:00 E SEXTA 15:30 AS 17:00
              <br />
              SÓCIO: R$ 97,00
              <br />
              NÃO SÓCIO: R$ 183,00
            </S.Text>
            <S.RedSubtitle>MATRÍCULA NÃO SÓCIO: R$50,00</S.RedSubtitle>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
            <S.Text>
              É obrigatório entregar os atestados médicos para a prática das
              atividades esportivas no clube, de acordo com a LEI 2.014/92 de
              15/07/1992.
            </S.Text>
            <S.Text>
              O Atestado médico deve ser específico a cada uma das modalidades,
              evitando quaisquer problemas de saúde aos associados.
            </S.Text>
            <S.Text>
              Para verificar suas condições de saúde e a aptidão para a prática
              da atividade física pretendida, o Departamento de Esportes
              recomenda que o associado procure o médico de sua confiança para
              fazer todos os exames necessários
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};

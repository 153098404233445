import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Hidroginástica</S.Title>
            <S.Text>
              A hidroginástica é um exercício que une ginástica aeróbica,
              localizada e relaxamento, praticado na água. A resistência natural
              da água multiplica o esforço exigido na realização dos movimentos,
              mas não oferece o perigo de lesões.
            </S.Text>
            <S.Text>
              A hidroginástica pode ser praticada por homens, mulheres, jovens e
              idosos que buscam se exercitar; pessoas ocupadas e que tem pouco
              tempo para fazer exercícios, atletas em treinamento, gestantes,
              pessoas que estão se recuperando de alguma lesão, magros, gordos,
              pessoas com algum tipo de deficiência e aqueles que querem uma
              outra opção para os seus programas normais de exercícios.
            </S.Text>
            <S.Text>
              <strong>Seus benefícios:</strong>
              <br />
              Aeróbico;
              <br />
              Força e Resistência Muscular;
              <br />
              Flexibilidade;
              <br />
              Composição Corporal.
              <br />
            </S.Text>
            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
            <S.ContentBlock>
              <S.Subtitle>Horário das Aulas</S.Subtitle>
              <S.Text>
                <span>SEG/QUA/SEX 9:00 às 9:50 e 10:00 às 10:50</span>
                <br />
                <br />
                Local: Piscina Recreativa
              </S.Text>
            </S.ContentBlock>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};

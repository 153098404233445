import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Dança de salão</S.Title>
            <S.Text>
              É uma das formas mais tradicionais de se dançar.Teve sua origem
              nos salões da nobreza francesa, ganhando bastante divulgação no
              final da década de 70 nas gafieiras, na televisão em tramas de
              novelas e programas de auditório.
            </S.Text>
            <S.Text>
              A Dança de Salão, no seu formato original é composta por três
              ritmos principais:
            </S.Text>
            <S.Text>
              O Samba que mantém algumas características do samba de gafieira e
              incorporou novos estilos e figuras podendo ser dançado em pagodes,
              bossa nova ou samba rock.
            </S.Text>
            <S.Text>
              O Bolero que é mais tranqüilo, também recebeu um novo formato com
              mais giros e pode ser dançado também em músicas atuais como MPB e
              baladas.
            </S.Text>
            <S.Text>
              O Soltinho que é um gênero muito divertido e é a mistura de alguns
              ritmos como swing, fox e outros, também pode ser praticado em uma
              grande variedade de estilos musicais.
            </S.Text>
            <S.Text>
              É uma ótima combinação para quem deseja começar a dançar.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
          </S.ContentBlock>
          <S.ColumnsView>
            <S.ContentBlock>
              <S.Subtitle>Horário das Aulas</S.Subtitle>
              <S.Text>
                Segunda-feira aula de dança de salão de 19hs às 21hs
                <br />
                R$ 70,00 - sócio
                <br />
                R$ 90,00 - não sócio
                <br />
              </S.Text>
              <S.Text>
                Matrícula: Matrícula não sócio: R$ 50,00
                <br />
              </S.Text>
            </S.ContentBlock>
          </S.ColumnsView>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};

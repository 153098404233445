import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Natação</S.Title>
            <S.Text>
              Considerado um esporte completo, a natação desenvolve a
              coordenação motora, a força, a resistência, a velocidade e o
              condicionamento físico geral. Dividida em níveis de aprendizagem
              I, II, III, Pré-competitivo e Competitivo, trabalha os inúmeros
              fundamentos da modalidade como adaptação, respiração, flutuação,
              etc., assim como os diversos estilos como nado crawl, costas,
              peito, borboleta e adaptados.
            </S.Text>
            <S.Subtitle>Evolução dos nados</S.Subtitle>
            <S.Text>
              O nado de peito, a borboleta e o golfinho têm um vínculo histórico
              comum. O nado de peito somente foi regulamentado como tal após o
              estilo crawl tê-lo substituído nas provas de nado livre.
              Posteriormente foi introduzido o estilo borboleta que, finalmente,
              evoluiu para o golfinho. Com o surgimento do crawl, o antigo
              estilo (peito) perdeu sua posição, por ser mais lento. Entretanto,
              havia interesse em manter o estilo clássico e por isso, foram
              regulamentadas as provas exclusivas para aquele estilo.
            </S.Text>
            <S.Text>
              Quando surgiu a braçada da borboleta, novamente decaiu o uso do
              estilo clássico e, isto, da mesma forma que ocorrera
              anteriormente, fez com que a FINA, por meio de regulamentação
              específica, separasse os dois estilos. Aperfeiçoou-se o estilo de
              batidas de pernas e ao invés de tesoura surgiu o movimento
              ondulante do golfinho, razão da denominação do novo estilo. O nado
              de costas, inicialmente, tinha por finalidade proporcionar meios
              de fácil flutuação para descansar o nadador. Somente nos jogos
              olímpicos de Paris, em 1900, é que surgiu este estilo como forma
              de competição. Inicialmente os braços eram levados simultaneamente
              para dentro da água e as pernas movimentam-se de forma semelhante
              à tesoura a frente. Daí evoluiu para uma borboleta invertida e,
              com o surgimento do estilo novo de frente, seus empréstimos
              técnicos chegaram ao nado de costas, que passou a ser usar os
              mesmos movimentos de pernas, alternados para baixo e para cima,
              com os braços também alternados, de trás para frente, em tração de
              dentro da água e em recuperação fora dela.
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.RedSubtitle>
              SOMENTE COM A AULA EXPERIMENTAL ASSINADA PELO PROFESSOR
            </S.RedSubtitle>
            <S.Title>Escolinha</S.Title>
            <S.Text>
              SEGUNDA E SEXTA - Cintia
              <br />
              08:00 ás 08:50- 05 á 08 anos  (LIMITE 12)
            </S.Text>
            <S.Text>
              SEGUNDA E SEXTA - Cintia e Denise (INICIANTES)
              <br />
              09:00 ás 09:45 - 03 á 04 anos (LIMITE 12)
              <br />
              10:00 ás 10:40 - 03 á 04 anos (LIMITE 12)
            </S.Text>
            <S.Text>
              TERÇA E QUINTA - Cintia
              <br />
              08:00 ás 08:50 - 06 á 08 anos (LIMITE 12)
            </S.Text>
            <S.Text>
              TERÇA E QUINTA - Cintia e Denise
              <br />
              10:00 á 10:40 - 03 á 04 anos (LIMITE 12)
              <br />
            </S.Text>
            <S.Text>
              SEGUNDA E QUARTA - Denise
              <br />
              18:00 ás 18:50 - 07 á 10 anos (LIMITE 12)
            </S.Text>
            <S.Text>
              TERÇA E QUINTA FEIRA - Denise
              <br />
              08:00 ás 08:50 - 06 á 11 anos (LIMITE 15)
              <br />
              09:00 ás 09:50 - 05 á 07 anos (LIMITE 12)
              <br />
            </S.Text>
            <S.Text>
              TERÇA E QUINTA - Fernanda
              <br />
              07:00 ás 07:50 - ADULTOS - APERFEIÇOAMENTO (LIMITE 15)
              <br />
              08:00 ás 08:50 - 10 á 14 anos - APERFEIÇOAMENTO (LIMITE 15)
              <br />
              09:00 ás 09:50 - 08 á 12 anos - APERFEIÇOAMENTO (LIMITE 15)
              <br />
              10:00 ás 10:50 - 08 á 10 anos - APERFEIÇOAMENTO (LIMITE 15)
              <br />
              16:00 ás 16:50 - 08 á 12 anos - APERFEIÇOAMENTO (LIMITE 15)
              <br />
              17:00 ás 17:50 - 12 á 14 anos - APERFEIÇOAMENTO (LIMITE 15)
              <br />
              18:00 ás 18:50 - 12 á 14 anos - APERFEIÇOAMENTO (LIMITE 15)
              <br />
            </S.Text>
            <S.Text>
              SEGUNDA E QUARTA - Dandara
              <br />
              18:00 ás 19:00 - 07 á 10 anos INICIAÇÃO (LIMITE 12)
              <br />
            </S.Text>
            <br />
            <br />
            <S.Title>Mensalidades</S.Title>
            <S.Text>
              SEGUNDA , QUARTA E SEXTA - Rodolfo
              <br />
              07:00 ás 08:00 - ADULTO APERFEIÇOAMENTO (LIMITE 15)
              <br />
              09:30 ás 10:20 - 06 á 08 anos - ADAPTAÇÃO AO MEIO LIQUIDO (LIMITE
              15)
              <br />
              VALOR SÓCIO
              <br />
              <br />
              2X NA SEMANA - R$96,00 (03 e 04 anos)
              <br />
              2X NA SEMANA - R$85,00 (05 em diante)
              <br />
              3X NA SEMANA - R$98,00 (Rodolfo)
              <br />
              VALOR DA TOUCA : R$ 40,00
              <br />
              <br />
              VALOR NÃO SÓCIO
              <br />
              2X NA SEMANA - R$154,00 (03 e 04 anos)
              <br />
              2X NA SEMANA - R$146,00 (05 em diante)
              <br />
              3X NA SEMANA - R$183,00 (Rodolfo)
              <br />
            </S.Text>
            <S.Text>
              <S.Title>Equipe</S.Title>
              <S.RedSubtitle>
                SOMENTE COM A AULA EXPERIMENTAL ASSINADA PELO PROFESSOR
              </S.RedSubtitle>
            </S.Text>
            <S.Text>
              SEGUNDA A QUINTA - Rômulo (24) 974045085
              <br />
              18:00 ás 19:00 - a partir de 12 anos FLEX - SABER NADAR O CRAW
              <br />
              SOCIO: R$ 107,00
              <br />
              NÃO SOCIO: R$ 183,00
              <br />
            </S.Text>
            <S.Text>
              SEGUNDA E QUARTA - Rômulo (24) 974045085
              <br />
              18:00 ás 19:00 - (a partir de 12 anos) FLEX - SABER NADAR O CRAW
              <br />
              SOCIO: R$ 85,00
              <br />
              NÃO SOCIO: R$ 146,00
              <br />
            </S.Text>
            <S.Text>
              TERÇA E SEXTA - Rômulo (24) 974045085
              <br />
              18:00 ás 19:00 - a partir de 12 anos FLEX - SABER NADAR O CRAW
              <br />
              SOCIO: R$ 85,00
              <br />
              NÃO SOCIO: R$ 146,00
              <br />
            </S.Text>
            <S.Subtitle>
              (EQUIPE FLEX OS ALUNOS SÃO CONTABILIZADOS JUNTOS LIMITE DE 15)
            </S.Subtitle>
            <S.Text>
              SEGUNDA, QUARTA E SEXTA - Rodolfo (24) 981558866
              <br />
              08:00 ás 09:30 - 09 á 12 anos
              <br />
              SOCIO: R$ 98,00
              <br />
              NÃO SOCIO: R$ 183,00
              <br />
            </S.Text>
            <S.Text>
              TERÇA, QUINTA E SEXTA - Denise/Dandara
              <br />
              18:00 ás 19:30 - 08 á 11 anos
              <br />
            </S.Text>
            <S.Text>
              SEGUNDA Á SEXTA - Dandara e Denise - PETIZ II À INFANTIL (Auxiliar
              Rodolfo - Pre. fisica Denise)
              <br />
              15:30 ás 18:00 - 12 á 14 anos
              <br />
            </S.Text>
            <S.Text>
              SEGUNDA Á SABADO - Rodolfo (24) 981558866 (Auxiliar Romulo - Pre.
              fisica Denise)
              <br />
              Segunda á Sexta 14:00 ás 17:00 e Sábado 07:00 ás 09:30
              <br />
              13 á 17 anos JUVENIL, JUNIOR E SENIOR
              <br />
            </S.Text>
            <S.Text>
              SEGUNDA Á SÁBADO - Rômulo (24) 974045085 (Auxiliar Dandara - Pre.
              fisica Denise)
              <br />
              Segunda á Sexta 15:00 ás 18:00 e Sábado 08:00 ás 10:30 - 12 á 14
              anos INFANTIL
              <br />
              <S.Subtitle>Matrícula para não sócio: R$ 50,00</S.Subtitle>
            </S.Text>
          </S.ContentBlock>

          <S.ContentBlock>
            <S.RedSubtitle>
              • As atividades esportivas para não sócios são permitidas apenas
              para menores de 18 anos.
            </S.RedSubtitle>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};

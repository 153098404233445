export interface AnnualStatement {
  year: number;
  documents: {
    name: string;
    filename: string;
  }[];
}

export const annualStatements: AnnualStatement[] = [
  {
    year: 2024,
    documents: [
      {
        name: "Balanço Patrimonial",
        filename: "BALANCO-PATRIMONIAL-2024.PDF",
      },
      {
        name: "DRE",
        filename: "DRE-2024.PDF",
      },
    ],
  },
  {
    year: 2023,
    documents: [
      {
        name: "Balanço Patrimonial",
        filename: "BALANCO-PATRIMONIAL-2023.PDF",
      },
      {
        name: "DRE",
        filename: "DRE-2023.PDF",
      },
    ],
  },
  {
    year: 2022,
    documents: [
      {
        name: "Balanço Patrimonial",
        filename: "balanco-patrimonial-2022.pdf",
      },
      {
        name: "DRE",
        filename: "DRE-2022.pdf",
      },
    ],
  },
  {
    year: 2021,
    documents: [
      {
        name: "Balanço patrimonial e DRE 2020/2021",
        filename: "BALANCO-PATRIMONIAL-DRE-2020-2021.PDF",
      },
    ],
  },
  {
    year: 2020,
    documents: [
      {
        name: "Balanço patrimonial e DRE",
        filename: "BALANCO_PATRIMONIAL_E_DRE_2020.PDF",
      },
    ],
  },
  {
    year: 2019,
    documents: [
      {
        name: "Balanço patrimonial e DRE",
        filename: "BALANCO_PATRIMONIAL_E_DRE_2019.PDF",
      },
    ],
  },
  {
    year: 2018,
    documents: [
      {
        name: "Balanço patrimonial e DRE",
        filename: "BALANCO_PATRIMONIAL_2018.PDF",
      },
      {
        name: "Demonstração de Fluxo de Caixa",
        filename: "DEMONSTRACAO_DO_FLUXO_DE_CAIXA_2018.PDF",
      },

      {
        name: "Demonstração do Resultado do Exercício 2018",
        filename: "DEMONSTRACAO_DO_RESULTADO_DO_EXERCICIO_2018.PDF",
      },
    ],
  },
  {
    year: 2017,
    documents: [
      {
        name: "Balanço patrimonial e DRE",
        filename: "BALANCO_PATRIMONIAL_2017.PDF",
      },
      {
        name: "Demonstração Superavit",
        filename: "DEMONSTRACAO_DO_SUPERAVIT_2017.PDF",
      },
    ],
  },
];

interface Member {
  name: string;
  image: string;
  role?: string;
}

export const directors: Member[] = [
  {
    name: "Gustavo Tramontin de Mattos",
    image: "gustavo-tramontin-de-mattos.jpg",
    role: "Presidente",
  },
  {
    name: "Expedito Gonçalves de Aquino",
    image: "expedito-goncalves-de-aquino.jpg",
    role: "Vice-presidente",
  },
  {
    name: "Alex Ribeiro da Silva",
    image: "alex_ribeiro_da_silva_esportes.jpg",
    role: "Esporte",
  },
  {
    name: "Alcenor Gomes Henriques Neto",
    image: "alcenor_gomes_henriques_neto_divulgacao_e_mkt.jpg",
    role: "Divulgação e Mkt",
  },
  {
    name: "Andre Luiz Martins Webler",
    image: "andre_luiz_martins_webler_administrativo.jpg",
    role: "Administrativo",
  },
  {
    name: "Claudio Augusto De O. Ferreira",
    image: "claudio_augusto_de_o_ferreira_financas_e_controle.jpg",
    role: "Finanças e Controle",
  },
  {
    name: "Enock França De Souza",
    image: "enock_franca_de_souza_social.jpg",
    role: "Social",
  },
  {
    name: "Frederico Paschoeto Silva",
    image: "frederico_paschoeto_silva_cultura.jpg",
    role: "Cultura",
  },
  {
    name: "Luis Sergio Barenco Pinto",
    image: "luis_sergio_barenco_pinto_patrimonio.jpg",
    role: "Patrimônio",
  },
  // {
  //   name: "Marcello Saleh Canedo",
  //   image: "marcello_saleh_canedo_comercial.jpg",
  //   role: "Comercial",
  // },
];

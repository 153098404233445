import React from "react";
import * as S from "./styles";
import futsal from "../../../../../assets/images/futsal-2025.jpg";
export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Futsal</S.Title>
            <S.Text>
              A versão mais aceita, para o surgimento do Futebol de Salão no
              Brasil, é a de que ele começou a ser praticado nos idos de 1940
              por jovens freqüentadores da Associação Cristã de Moços, em São
              Paulo. Enfrentando dificuldades para encontrar campos de Futebol
              para divertimento nas suas horas de lazer, improvisaram "peladas"
              nas quadras de basquete e hóquei, aproveitando as traves usadas na
              prática desse último esporte. De início as "equipes" variavam de
              número, tendo cinco, seis e até sete jogadores, sendo pouco a
              pouco fixado o limite de cinco. As bolas eram de crina vegetal ou
              serragem, sofrendo sucessivas modificações, inclusive com uso de
              cortiça granulada. Como as bolas de ar utilizadas depois saltavam
              muito e saíam freqüentemente das quadras, posteriormente tiveram
              seu tamanho diminuído e o peso aumentado. Daí o "Futebol de Salão"
              ser chamado também de "esporte de bola pesada".
            </S.Text>
            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
          </S.ContentBlock>
          <S.ContentBlock>
            <div className="image">
              <img className="img2025" src={futsal} alt="tabela" />
            </div>
            <S.Text>
              ⦁ MATRICULA SÓCIO: R$ 90,00
              <br />
              ⦁ MENSALIDADE SÓCIO R$ 139,00
              <br />
            </S.Text>
            <S.Text>
              ⦁ MATRICULA NÃO SÓCIO: 120,00
              <br />
              • MENSALIDADE NÃO SÓCIO R$ 191,00
              <br />
            </S.Text>
            <S.Text>
              <S.Subtitle>
                • UNIFORMES DIRETO COM OS PROFESSORES R$350,00
              </S.Subtitle>
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};

import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Karatê</S.Title>
            <S.Text>
              uma arte marcial japonesa, desenvolvida em Okinawa, na região
              norte do país. A arte marcial, que era transmitida oralmente de
              mestre para discípulo, foi reconhecida pela Associação Japonesa de
              Artes Marciais em 1930. A atividade se propagou. No decorrer do
              século XX, chegou ao Brasil e tomou gosto público a partir do
              sucesso de um filme chamado "Karatê Kid".
            </S.Text>
            <S.Text>
              Atualmente, o karatê é reconhecido mundialmente como uma arte
              marcial que trabalha o controle total do corpo e da mente do
              praticante, tendo como princípios incentivar o esforço e ensinar o
              respeito ao próximo. A prática desta atividade ainda proporciona
              vários benefícios à saúde física e psicossocial, como força,
              resistência, flexibilidade articular, redução de níveis de
              ansiedade, melhora na auto-estima, autoconhecimento e
              desenvolvimento moral.
            </S.Text>
            <S.Subtitle>Informações sobre as aulas</S.Subtitle>
            <S.Text>
              Interessados devem providenciar Kimono para praticar a arte
              marcial. As aulas iniciais poderão ser efetuadas com calça de
              moletom e blusa de malha sem botão. É necessária a apresentação de
              atestado médico anual, que libera o participante para a prática
              esportiva, e documento de autorização dos pais, no caso de menores
              de idade.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>
            </S.ContentBlock>
            <S.ContentBlock>
              <S.Subtitle>SENSEI VICTOR ANDRADE</S.Subtitle>
              Idade: 6 A 17 anos / Adultos (Sócios)
              <br />
              Valor: R$ 90,00 para Sócio - R$ 130,00 para não sócio
              <br />
              Matrícula: R$ 50,00 - Não sócio
              <br />
              Segunda e Quarta-feira: 18h às 19h
              <br />
              Local: 2º Andar do Gastrobar
              <br />
              Data de Inícil- Quando tivermos no mínimo 3 alunos
            </S.ContentBlock>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};

import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Subtitle>Horários de Funcionamento</S.Subtitle>
            <S.Text>
              Segunda à Sexta-feira - 06h00 às 22h00
              <br />
              Sábados - 07h00 às 13h00
              <br />
              Domingos - 08h00 às 12h00
              <br />
              Feriados - 07h00 às 13h00
              <br />
              <span>Obs: Somente para sócios</span>
              <br />
              <br />
            </S.Text>
            <S.RedSubtitle>Limite:1000 alunos</S.RedSubtitle>
            <S.RedSubtitle>
              Idade Mínima: 14 anos (Menores apenas com personal)
            </S.RedSubtitle>
            <S.Text>
              <br />
              Além da musculação o sócio terá direito a frequentar as seguintes
              aulas:
              <br />
              * Dança : Segundas e Quarta 07h00 prof. Viviane
              <br />
              * Step : Quarta e Sexta 07h30 com a professora Fernanda
              <br />
              * Funcional: Terça e Quinta 7:00 com a professora Denise
              <br />
              * Hit 30 segunda a quinta 19:00 prof Fernando
              <br />
            </S.Text>
            <S.Text>
              <span>Valores:</span>
              <br />
              Mensalidade: R$ 109,00
              <br />
              Diaria: R$ 20,00
              <br />
              Camisa: R$ 40,00 (Aguardando chegar)
              <br />
              Isento de taxa de matrícula
              <br />
              <br />
              <span>O personal deverá pagar uma taxa de R$ 109,00 mensal</span>
              <br />
              Aula experimental autorizada somente de segunda a sexta-feira
              (Sábados, domingos e feriados não liberar)
              <br />
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};

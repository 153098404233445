import React from "react";

import * as S from "./styles";
import soccer from "assets/images/soccer-2025.jpg";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Futebol</S.Title>
            <S.Text>
              O futebol é um dos esportes mais populares no mundo. Praticado em
              centenas de países, este esporte desperta tanto interesse em
              função de sua forma de disputa atraente. Embora não se tenha muita
              certeza sobre os primórdios do futebol, historiadores descobriram
              vestígios dos jogos de bola em várias culturas antigas. Estes
              jogos de bola ainda não eram o futebol, pois não havia a definição
              de regras como há hoje, porém demonstram o interesse do homem por
              este tipo de esporte desde os tempos antigos.
            </S.Text>
            <S.Text>
              O futebol tornou-se tão popular graças a seu jeito simples de
              jogar. Basta uma bola, equipes de jogadores e as traves, para que,
              em qualquer espaço, crianças e adultos possam se divertir com o
              futebol. Na rua, na escola, no clube, no campinho do bairro ou até
              mesmo no quintal de casa, desde cedo jovens de vários cantos do
              mundo começam a praticar o futebol.
            </S.Text>

            <S.Subtitle>Quer Marcar um Jogo ?</S.Subtitle>
            <S.Text>
              Você faz parte de um grupo de amigos que se reúne toda semana para
              bater aquela pelada?... Se a resposta for sim, a solução está
              aqui, no Clube dos Funcionários da CSN. Entre em contato conosco e
              marque um jogo com a nossa equipe de veteranos.
            </S.Text>
            <S.Subtitle>Confira as datas agendadas:</S.Subtitle>
            <S.Text>
              Maiores de 40 anos - Sábados - 15:00 horas.
              <br />
              Misto - Quartas-Feiras - 19:00 horas .
            </S.Text>
            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
            <div className="image">
              <img className="img2025" src={soccer} alt="Futebol" />
            </div>
            <S.Text>
              ⦁ MATRICULA SÓCIO: R$90,00
              <br />
              • MENSALIDADE SÓCIO R$ 179,00
              <br />
            </S.Text>
            <S.Text>
              ⦁ MATRICULA NÃO SÓCIO : R$120,00
              <br />
              • MENSALIDADE NÃO SÓCIO R$ 239,00
              <br />
              <S.Subtitle>
                • UNIFORMES DIRETO COM OS PROFESSORES R$350,00
              </S.Subtitle>
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};

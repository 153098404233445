import React from "react";

import * as S from "./styles";
import tableImg from "assets/images/basquete-2025.jpg";
export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Basquete</S.Title>
            <S.Text>
              O basquetebol é um desporto em plena evolução no mundo, cada vez
              mais difundido nas competições de alto nível e também como forma
              de recreação. Teve nos Estados Unidos o seu berço e lá também se
              constata a sua maior difusão e, conseqüentemente, o maior
              desenvolvimento técnico e a melhor performance em termos de
              ranking internacional. O basquetebol é um esporte completo,
              constituindo-se numa sucessão de esforços intensos e breves em
              vários ritmos, podendo alinhar corridas, saltos e lançamentos. Por
              estas razões coordena o ritmo e os movimentos humanos.
            </S.Text>
            <S.Text>
              Neste esporte, o trabalho físico dos grupamentos musculares ocorre
              de maneira coordenada e ritmada e, sem dúvida, os membros
              inferiores são os mais exigidos, embora esses movimentos sejam
              executados com muita flexibilidade e contrações rápidas. Os
              músculos abdominais e dorsais são freqüentemente exigidos, através
              de rotações, flexões, etc. Os músculos dos membros superiores
              realizam um trabalho onde os movimentos suaves, precisos e
              ajustados constituem artifícios essenciais nas conclusões dos
              passes, dribles e arremessos. O equilíbrio emocional de uma parte
              e a técnica da outra proporcionam a precisão e segurança que devem
              coexistir com a velocidade e a explosão. No campo moral, o
              basquetebol desperta no praticante o amor próprio, o espírito de
              cooperação, a solidariedade, o respeito ao próximo, a lealdade, a
              disciplina, a força de vontade etc. Em síntese, podemos concluir
              que o basquetebol é um excelente meio de formação física, moral e
              social do indivíduo.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários.
              </S.Text>

              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
            </S.ContentBlock>
          </S.ContentBlock>
          <S.ContentBlock>
            <div className="image">
              <img
                className="img2025"
                src={tableImg}
                alt="tabela de basquete"
              />
            </div>
            <br />
            <S.Title>Mensalidade:</S.Title>
            <br />
            <S.Subtitle>Escolinha</S.Subtitle>
            <S.Text>
              <span>2 vezes na semana</span>
            </S.Text>
            sócio: R$ 76,00
            <br />
            Não sócio: R$ 128,00
            <br />
            <br />
            <S.Text>
              <span>3 vezes na semana</span>
            </S.Text>
            sócio: R$ 98,00 Não sócio: R$ 183,00 Pré-equipe
            <br />
            sócio: R$ 76,00
            <br />
            Não sócio: R$ 128,00
            <br />
            <br />
            <S.Subtitle>Equipe</S.Subtitle>
            sócio: R$ 98,00
            <br />
            Não sócio: R$183,00
            <br />
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};

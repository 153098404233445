export interface FiscalCommision {
  year: number;
  documents: {
    name: string;
    filename: string;
  }[];
}

export const fiscalCommissions: FiscalCommision[] = [
  {
    year: 2024,
    documents: [
      {
        name: "Parecer da Comissão Fiscal",
        filename: "PARECER_DO_EXERCICIO_2024.PDF",
      },
    ],
  },
  {
    year: 2023,
    documents: [
      {
        name: "Parecer da Comissão Fiscal",
        filename: "PARECER_DO_EXERCICIO_2023.PDF",
      },
    ],
  },
  {
    year: 2022,
    documents: [
      {
        name: "Parecer da Comissão Fiscal",
        filename: "PARECER_DO_EXERCICIO_2022.PDF",
      },
    ],
  },
  {
    year: 2021,
    documents: [
      {
        name: "Parecer da Comissão Fiscal",
        filename: "PARECER_DO_EXERCICIO_2021.PDF",
      },
    ],
  },
  {
    year: 2020,
    documents: [
      {
        name: "Parecer da Comissão Fiscal",
        filename: "PARECER_DO_EXERCICIO_2020.PDF",
      },
    ],
  },
  {
    year: 2019,
    documents: [
      {
        name: "Parecer da Comissão Fiscal",
        filename: "PARECER_DO_EXERCICIO_2019.PDF",
      },
    ],
  },
  {
    year: 2018,
    documents: [
      {
        name: "Parecer da Comissão Fiscal",
        filename: "PARECER_DO_EXERCICIO_2018.PDF",
      },
    ],
  },
  {
    year: 2017,
    documents: [
      {
        name: "Parecer da Comissão Fiscal",
        filename: "PARECER_DO_EXERCICIO_2017.PDF",
      },
    ],
  },
];

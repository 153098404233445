import React from "react";

import * as S from "./styles";

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Beach Tennis</S.Title>
            <S.Text>
              O Beach Tennis é um esporte que faz uma mescla de tênis, vôlei de
              praia, badminton e frescobol. Sim, ele é real e tem caido no gosto
              do povo. O beach tennis é particado na areia e possui regras
              próprias proporcionando campeonatos disputadíssimos!
            </S.Text>
            <S.Text>
              Desde que chegou ao Brasil, em 2008, se popularizou não apenas em
              cidades do litoral, mas também em otros municipios.
            </S.Text>
            <S.Text>
              O tênis de praia, (Beach Tennis), é um esporte supernovo,
              inspirado no frescobol e Foi criado há apenas 34 anos na província
              italiana de Ravenna e pode ser jogado individualmente ou em
              duplas.
            </S.Text>
            <S.Text>
              O tênis de praia, (Beach Tennis), é um esporte supernovo,
              inspirado no frescobol e Foi criado há apenas 34 anos na província
              italiana de Ravenna e pode ser jogado individualmente ou em
              duplas.
            </S.Text>
            <S.Text>
              Em 1996, as regras foram padronizadas pela Federação Internacional
              de Tênis (ITF) e, desde então, campeonatos ocorrem em todo o mundo
              com atletas profissionais da modalidade.
            </S.Text>

            <S.ContentBlock>
              <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
              <S.Text>
                É obrigatório entregar os atestados médicos para a prática das
                atividades esportivas no clube, de acordo com a LEI 2.014/92 de
                15/07/1992.
              </S.Text>
              <S.Text>
                O Atestado médico deve ser específico a cada uma das
                modalidades, evitando quaisquer problemas de saúde aos
                associados.
              </S.Text>
              <S.Text>
                Para verificar suas condições de saúde e a aptidão para a
                prática da atividade física pretendida, o Departamento de
                Esportes recomenda que o associado procure o médico de sua
                confiança para fazer todos os exames necessários
              </S.Text>
            </S.ContentBlock>

            <S.ContentBlock>
              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
              <S.Subtitle>
                <strong>Horário das Aulas</strong>
              </S.Subtitle>
            </S.ContentBlock>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Title>CLARINHA (24)99811-1323</S.Title>
            <S.Text>
              (Segunda a Sexta-feira)
              <br />
              07h00 às 08h00
              <br />
              08h00 às 09h00
              <br />
              09h00 às 10h00 - Horário Kids
              <br />
              14h00 às 15h00 - Horário Teen
              <br />
              15h00 às 16h00 - Horário Teen
              <br />
              16h00 às 17h00 - Horário Teen
              <br />
              <br />
              * Turma Kids/Teen - Máximo 8 Alunos
              <br />
              * Turma Adulto - Máximo 6 Alunos
              <br />
              * Mensalidade Kids - Até os 17 anos.
              <br />
              <br />
              Mensalidade para Adultos Sócios:
              <br />
              R$135,00 (1x na semana) - 1 hora de aula
              <br />
              R$180,00 (2x na semana) - 1 hora de aula
              <br />
              R$230,00 (3x na semana) - 1 hora de aula <br />
              <br />
              Mensalidade Kids Sócios
              <br />
              R$115,00 (1x na semana) - 1 hora de aula
              <br />
              R$160,00 (1x na semana) - 1 hora de aula
              <br />
              R$210,00 (1x na semana) - 1 hora de aula
              <br />
              <br />
              Mensalidade Kids Não Sócios:
              <br />
              R$175,00 (1x na semana) - 1 hora de aula
              <br />
              R$220,00 (2x na semana) - 1 hora de aula
              <br />
              R$270,00 (3x na semana) - 1 hora de aula
              <br />
            </S.Text>
            <S.Title>THALLES (24)998825240</S.Title>
            <S.Text>
              Segunda e Quarta
              <br />
              7h avançada <br />
              8h avançada <br />
              9h intermediário <br />
              16 iniciante/int Juvenil
              <br />
              17h avançada <br />
              18h iniciante <br />
              19h intermediário <br />
              20h avançada <br />
              21h avançada <br />
              <br />
              Terça e sexta
              <br />
              7h avançada <br />
              8h iniciante <br />
              9h iniciante <br />
              16h iniciante <br />
              18h iniciante <br />
              19h intermediário <br />
              20 avançada <br />
              21 intermediário <br />
              <br />
              Turma de 1 dia
              <br />
              Quarta 7h iniciante <br />
              Sexta 17h iniciante <br />
              <br />
              <span>Mensalidade:</span>
              *SÓCIO:
              <br />
              R$135,00 (1x NA SEMANA 01:00 DE AULA)
              <br />
              R$180,00 (2x NA SEMANA 01:00 DE AULA)
              <br />
              <br />
              *NÃO SÓCIO:
              <br />
              R$180,00 (1x NA SEMANA 01:00 DE AULA)
              <br />
              R$240,00 (2x NA SEMANA 01:00 DE AULA)
              <br />
            </S.Text>
          </S.ContentBlock>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};

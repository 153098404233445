import React from "react";

import * as S from "./styles";

// TODO: Get content from the site https://www.clubedosfuncionarios.com.br/esportes/2/bale/

export const Main: React.FC = () => {
  return (
    <S.Main>
      <S.Restrainer>
        <S.MainContainer>
          <S.ContentBlock>
            <S.Title>Balé</S.Title>
            <S.Text>
              Balé, Ballet ou Balê, é o nome dado a um estilo de dança e a sua
              performance. O termo deriva do italiano ballare que significa
              bailar. Os princípios básicos do balé são:
            </S.Text>
            <S.Text>
              Postura ereta;
              <br />
              Uso do en dehors (rotação externa dos membros inferiores);
              <br />
              Verticalidade corporal;
              <br />
              Simetria.
            </S.Text>
            <S.Text>
              O ballet tem suas raízes na Itália renascentista através das
              pantomimas (peças de teatro sem falas, utilizando apenas
              expressões faciais e corporais, geralmente improvisada)que eram
              realizados por atores e circenses em grandes salões para membros
              da corte.
            </S.Text>
            <S.Text>
              O casamento da italiana Catarina de Médicis com o Rei Henrique II
              da França em 1533 deu um importante impulso para o desenvolvimento
              do balé. Diversos artistas especializados em grandes e luxuosos
              espetáculos foram trazidos da Itália. Em 1581 Catarina de Médicis
              produziu o Ballet Comique de la Reine em Paris sob a direção do
              músico italiano Baldassarino de Belgiojoso ou Balthazar de
              Beaujoyeulx, nome que adotou na França. O balé tomou a forma na
              qual é conhecido hoje, na França durante o reinado de Luis XIII.
              No ano de 1661, seu filho Luis XIV fundou a Académie de Musique et
              de Danse, com o objetivo de sistematizar, preservar a qualidade e
              de fiscalizar o ensino e a produção do balé. Luis XIV nomeou
              Charles Louis Pierre de Beauchamps para tomar a frente da
              instituição que foi dissolvida em 1780.
            </S.Text>
            <S.Text>
              Os chamados balés de repertório se baseiam em composições musicais
              que contribuíram para torná-lo popular na Europa e depois no resto
              do mundo. Alguns dos balés mais notáveis são: Coppélia, de Léo
              Delibes, O Pássaro de Fogo, de Igor Stravinsky, O Quebra-Nozes e O
              Lago dos Cisnes, ambos de Tchaikovsky.
            </S.Text>
            <S.Text>
              A partir do Romantismo, as mulheres passaram a se destacar e
              contribuir para o aperfeiçoamento da arte. Marie Camargo por
              exemplo, criou o jeté, o pas de basque e o entrechat quatre, além
              de encurtar os vestidos até acima dos tornozelos e calçar sapatos
              sem saltos.
            </S.Text>
          </S.ContentBlock>
          <S.ContentBlock>
            <S.Subtitle>A importância dos atestados médicos</S.Subtitle>
            <S.Text>
              É obrigatório entregar os atestados médicos para a prática das
              atividades esportivas no clube, de acordo com a LEI 2.014/92 de
              15/07/1992.
            </S.Text>
            <S.Text>
              O Atestado médico deve ser específico a cada uma das modalidades,
              evitando quaisquer problemas de saúde aos associados.
            </S.Text>
            <S.Text>
              Para verificar suas condições de saúde e a aptidão para a prática
              da atividade física pretendida, o Departamento de Esportes
              recomenda que o associado procure o médico de sua confiança para
              fazer todos os exames necessários
            </S.Text>
          </S.ContentBlock>
          <S.ColumnsView>
            <S.ContentBlock>
              <S.RedSubtitle>
                • As atividades esportivas para não sócios são permitidas apenas
                para menores de 18 anos.
              </S.RedSubtitle>
              <S.ContentBlock>
                <S.Text>
                  <span>Horários as Aulas</span>
                </S.Text>
                <S.Text>
                  (Segunda e quarta-feira)
                  <br />
                  às 16h às 17u30 Avançado e intermédio - (14, 15 e 16 anos)
                  <br />
                  17h30 às 18h10 - (3 e 4 anos)
                  <br />
                  18h 10 às 18h50 ( 4 e 5 anos )<br />
                  18h50 às 19h30 Preparatório - (7,8 e 9 anos )<br />
                </S.Text>

                <S.Text>
                  (Terça e quinta-feira)
                  <br />
                  17h15 às 18h15 3 e 4 anos
                  <br />
                  18h15 às 19h15 - Iniciação à ponta (10,11 e 12 )<br />
                  19h15 às 20h30 (Avaliação)
                  <br />
                </S.Text>
                <S.Text>
                  (Sexta-feira)
                  <br />
                  19h00 às 20h30 - Adultos(masc/fem), homens são bolsistas.
                </S.Text>
                <S.Text>
                  <span>
                    Mensalidade: (Devem ser pagas na secretaria do clube dos
                    funcionários)
                  </span>
                  <br />
                  R$ 75,00 - sócio
                  <br />
                  R$ 112,50 - não sócio
                  <br />
                </S.Text>
                <S.Text>
                  <span>Matrícula:</span>
                  <br />
                  Matrícula não sócio: R$ 50,00
                  <br />
                  Local: Sede social 5º andar.
                  <br />
                </S.Text>
              </S.ContentBlock>
            </S.ContentBlock>
          </S.ColumnsView>
        </S.MainContainer>
      </S.Restrainer>
    </S.Main>
  );
};
